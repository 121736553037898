import { default as cartESLbrQeHyoMeta } from "/src/pages/@[slug]/cart.vue?macro=true";
import { default as checkout9H7s5QqQ8iMeta } from "/src/pages/@[slug]/checkout.vue?macro=true";
import { default as indexrEdYouqpFIMeta } from "/src/pages/@[slug]/index.vue?macro=true";
import { default as _91product_id_939hEyv6TS3mMeta } from "/src/pages/@[slug]/product/[product_id].vue?macro=true";
import { default as tracking_45by_45id2xFaz5LUPrMeta } from "/src/pages/@[slug]/tracking-by-id.vue?macro=true";
import { default as trackingwocUk06fwcMeta } from "/src/pages/@[slug]/tracking.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as callbackA98wpZU6EJMeta } from "/src/pages/payment/callback.vue?macro=true";
import { default as component_45stubgxFpV4faLuMeta } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubgxFpV4faLu } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "@slug-cart",
    path: "/@:slug()/cart",
    meta: cartESLbrQeHyoMeta || {},
    component: () => import("/src/pages/@[slug]/cart.vue")
  },
  {
    name: "@slug-checkout",
    path: "/@:slug()/checkout",
    meta: checkout9H7s5QqQ8iMeta || {},
    component: () => import("/src/pages/@[slug]/checkout.vue")
  },
  {
    name: "@slug",
    path: "/@:slug()",
    meta: indexrEdYouqpFIMeta || {},
    component: () => import("/src/pages/@[slug]/index.vue")
  },
  {
    name: "@slug-product-product_id",
    path: "/@:slug()/product/:product_id()",
    meta: _91product_id_939hEyv6TS3mMeta || {},
    component: () => import("/src/pages/@[slug]/product/[product_id].vue")
  },
  {
    name: "@slug-tracking-by-id",
    path: "/@:slug()/tracking-by-id",
    meta: tracking_45by_45id2xFaz5LUPrMeta || {},
    component: () => import("/src/pages/@[slug]/tracking-by-id.vue")
  },
  {
    name: "@slug-tracking",
    path: "/@:slug()/tracking",
    meta: trackingwocUk06fwcMeta || {},
    component: () => import("/src/pages/@[slug]/tracking.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "payment-callback",
    path: "/payment/callback",
    meta: callbackA98wpZU6EJMeta || {},
    component: () => import("/src/pages/payment/callback.vue")
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubgxFpV4faLu
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubgxFpV4faLu
  }
]