<template>
  <header
    class="p-header p-header--hasBanner w-full z-30"
    id="header"
    ref="ref_header"
  >
    <div class="p-header__disabler js-header__disabler" :class="{'show': is_menu_shown}" @click="hide_menu"></div>

    <!--    <div ref="ref_banner">-->
    <!--      <nuxt-link-->
    <!--        :to="URL_"-->
    <!--        class="anim-headerBanner flex w-full h-10 flex-center items-center justify-center text-center bg-secondary text-white text-xs sm:text-sm hover:hover:bg-secondary/80 hover:text-white transition-colors"-->
    <!--      >-->
    <!--      </nuxt-link>-->
    <!--    </div>-->

    <div class="relative">
      <div class="p-header__container">
        <div class="relative phone:container container-wide py-3">
          <div class="flex flex-wrap items-center justify-between">
            <div class="flex items-center">
              <nuxt-link
                :to="sprintf(URL_STORE_SINGLE, store_slug)"
                class="flex items-center"
              >
                {{ data_store.title }}
              </nuxt-link>
            </div>

            <div class="flex">
              <base-button
                tag="router-link"
                :to="sprintf(URL_STORE_TRACKING, store_slug)"
                type="primary"
                class="ml-4"
                plain
                v-if="!is_tracking_page"
              >
                رهگیری
              </base-button>

              <nuxt-link
                :to="sprintf(URL_STORE_CART, store_slug)"
                class="relative flex items-center lg:order-2"
              >
                <nuxt-icon name="cart" class="text-xl"/>

                <div
                  class="absolute left-0 top-0 -translate-y-1/2 -translate-x-1/2 bg-primary text-white rounded-full text-xxs px-1"
                  v-if="cart_total_quantity"
                >
                  {{ cart_total_quantity > 9 ? "9+" : cart_total_quantity }}
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { URL_STORE_CART, URL_STORE_SINGLE, URL_STORE_TRACKING } from "assets/js/consts/urls.const.js";
import useCart from "assets/js/composables/cart/useCart.js";
import { sprintf } from "sprintf-js";
import { useRoute } from "vue-router";
import useServerFetch from "assets/js/composables/fetcher/useServerFetch.js";
import { ROUTE_STORE_STORE_SHOW } from "assets/js/consts/publicRoutes.consts.js";
import { computed, onBeforeUnmount, onMounted } from "vue";

const route = useRoute();

let banner_height = 0;
const HEADER_OFFSET = 200;
const is_menu_shown = ref(false);
const ref_header = ref(null);
const ref_banner = ref(null);

const {
  cart_total_quantity,
} = useCart();

const store_slug = computed(() => {
  return route.params.slug;
});

const is_tracking_page = computed(() => {
  return route.path === sprintf(URL_STORE_TRACKING, route.params.slug);
});

const {
  data: data_store,
  filters: filters_store,
  is_loading: is_loading_store,
  refresh: refresh_store,
  has_error: has_error_store,
  status: status_store,
  fetch: fetch_store,
  filter: filter_store,
} = await useServerFetch(sprintf(ROUTE_STORE_STORE_SHOW, route.params.slug), {
  server: true,
});

const show_menu = () => {
  is_menu_shown.value = true;
  document.body.style.overflow = "hidden";
};

const hide_menu = () => {
  is_menu_shown.value = false;
  document.body.style.overflow = "auto";
};

const toggle_menu = () => {
  if (is_menu_shown.value)
    hide_menu();
  else
    show_menu();
};

const handle_scroll = ($event) => {
  if (!ref_header.value) return;
  const scroll_top = window.scrollY;

  if (scroll_top > HEADER_OFFSET) {
    ref_header.value.classList.add("p-header--fixed");

  } else if (scroll_top <= banner_height) {
    ref_header.value.classList.remove("p-header--fixed");
  }
};

onMounted(() => {
  if (ref_banner.value)
    banner_height = ref_banner.value.offsetHeight;

  document.addEventListener("scroll", handle_scroll);
});

onBeforeUnmount(() => {
  document.removeEventListener("scroll", handle_scroll);
});

</script>

<style lang="scss" scoped>
.p-header {
  $parent: #{&};

  position: relative;
  z-index: 60;

  padding-bottom: 64px;

  @at-root #{$parent}__container {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;

    box-shadow: 0 0 0 rgba(black, 0.1);
    transition: 0.4s all;
  }

  @at-root #{$parent}__disabler {
    display: none;

    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(black, 0.4);
    z-index: 0;

    @include media($media-phone) {
      z-index: -1;
    }

    &.show {
      display: block;
    }
  }

  &--fixed {
    #{$parent}__container {
      position: fixed;

      will-change: top;
      animation-name: anim-header-fixed;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;

      background: white;
      box-shadow: 0 5px 10px rgba(black, 0.1);
    }
  }

  &--hasBanner {
    #{$parent}__helper {
      height: 104px;
    }
  }
}

@keyframes anim-header-fixed {
  from {
    top: -200px
  }
  to {
    top: 0
  }
}

</style>
